import { RawCellContent } from "hyperformula";
import { AutoRendered } from "../AutoRendered";
import { RowWithType } from "../RowWithType";
import { RenderableBase } from "../RenderableBase";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { HoverLabel } from "src/classes/RenderedDoc";

interface Labels {
  [key: string]: string;
}
type RowBuilder<TForm extends RenderableBase, L extends Labels> = ({
  data,
  labels,
  columnReference,
  rowNumber,
}: BuiltRow<TForm, L>) => RowWithType;

type ConfidenceRowBuilder<TForm extends RenderableBase, L extends Labels> = ({
  data,
  labels,
  columnReference,
  rowNumber,
}: BuiltRow<TForm, L>) => RawConfidenceContent[];

type RowBuilderWithConfidence<TForm extends RenderableBase, L extends Labels> = ({
  data,
  labels,
  columnReference,
  rowNumber,
}: BuiltRow<TForm, L>) => {
  dataRow: RowWithType;
  confidenceRow: RawConfidenceContent[];
};

interface BuiltRow<TForm, L extends Labels> {
  data: TForm;
  labels: L;
  columnReference: DynamicColumnReference;
  rowNumber: number;
}

type DynamicColumnReference = (header: string, startAt?: StartAt) => string;

type RowResolver = (
  builder: (proxyData: any) => {
    label: string;
    value?: any;
    formula?: string;
  },
) => RowWithType;
export type StartAt = "beginning" | "end";

/**
 * AutoRenderedSheetBuilder - A class for building a rendered document's body with an `addRow` function.
 *
 * The `addRow` function allows you to add a row to the body and returns the row number. This row number can be used to reference the added row,
 * which is particularly useful for building formulas or other data-dependent calculations.
 */

export class AutoRenderedSheetBuilder<
  T extends RenderableBase,
  L extends Labels,
> extends AutoRendered<T> {
  body: RowWithType[] = [];
  protected _highlightedRowLabels: string[] = [];
  protected _percentageRowLabels: string[] = [];
  protected confidenceBody: RawConfidenceContent[][] = [];

  constructor(
    protected data: T,
    protected labels: L,
    public startingRow: number,
    public columnId: string,
  ) {
    super(data, columnId, startingRow);
    this.setDefaultHoverInfos();
  }

  /**
   * Overrides the confidence body with the provided content.
   * @param confidenceBody
   * @returns self
   */
  withConfidence(confidenceBody: RawConfidenceContent[][]) {
    this.confidenceBody = confidenceBody;
    return this;
  }

  /**
   * Finds the row index by header.
   * @param {string} header - The header to search for.
   * @returns {number} - The row number if found, otherwise -1.
   */
  findRowIndex(header: string, startAt: StartAt = "beginning"): number {
    const index =
      startAt === "beginning"
        ? this.body.findIndex((row) => row[0] === header) + 1
        : this.body.findLastIndex((row) => row[0] === header) + 1;
    if (index === 0) {
      throw new Error(`Row for ${header} not found.`);
    }
    return index + this.startingRow;
  }

  /**
   * Returns a reference in a specific column for a given header.
   * @param {string} header - The header to reference.
   * @returns {string} - The reference in the column.
   */
  referenceInColumn(header: string, startAt: StartAt = "beginning"): string {
    const rowIndex = this.findRowIndex(header, startAt);
    return `${this.columnId}${rowIndex}`;
  }

  getTypedRow(rowIndex: number): RowWithType {
    return this.body[rowIndex - this.startingRow];
  }

  getRow(excelRowIndex: number): RawCellContent[] {
    if (excelRowIndex < this.startingRow)
      throw new Error(`Rows start at ${this.startingRow} but got ${excelRowIndex}`);
    const actualIndex = excelRowIndex - this.startingRow - 1;
    const row = this.body[actualIndex];
    if (!row) {
      throw new Error(`Row for index ${actualIndex} not found.`);
    }
    return row.map((cell) => cell);
  }

  /**
   * Adds a row to the confidence body.  The analog to `addRow`.
   * If no rowBuilder is provided, an empty row will be added.
   * @param {Function} rowBuilder - A function that returns an array representing a row.
   */

  /**
   * Adds a row to the body.
   *
   * If no rowBuilder is provided, an empty row will be added.
   * If no rowDataType is provided, it will be calculated based on the row's values.
   *
   * @param {Function} rowBuilder - A function that returns an array representing a row.
   * @param {string} rowDataType - The type of data in the row, either "text" or "number".
   * @param {"highlighted" | "normal"} highlightStatus - Whether the row should be highlighted.
   * @returns {AutoRenderedSheetBuilder} - The current instance of the AutoRenderedSheetBuilder.
   */
  addRow(
    rowBuilder: RowBuilder<T, L> = () => [""],
    rowDataType: "text" | "number" | undefined = undefined,
    highlightStatus: "highlighted" | "normal" = "normal",
  ): AutoRenderedSheetBuilder<T, L> {
    const boundReferenceInColumn = this.referenceInColumn.bind(this);

    const rowNumber = this.body.length + 1;
    const rowIndex = this.body.length;
    const row = rowBuilder({
      data: this.data,
      labels: this.labels,
      columnReference: boundReferenceInColumn,
      rowNumber,
    });
    row.rowDataType = rowDataType;

    this.body.push(row);
    if (highlightStatus === "highlighted") {
      this.highlightedRowIndexes = [...this.highlightedRowIndexes, rowIndex];
    }
    return this;
  }

  addHoverLabelForPrevRow(hoverLabel: HoverLabel): AutoRenderedSheetBuilder<T, L> {
    const rowIndex = this.body.length - 1;
    const label = this.body[rowIndex][0];
    if (!label) {
      throw new Error("Cannot add hover label to row without label");
    }
    this.fillHoverInfos();
    this.setHoverInfoForLabel(label.toString(), hoverLabel);
    return this;
  }

  setHoverInfoForLabel(label: string, hoverLabel: HoverLabel): AutoRenderedSheetBuilder<T, L> {
    super.setHoverInfoForLabel(label, hoverLabel);
    return this;
  }

  addRowWithConfidence(
    rowBuilder: RowBuilderWithConfidence<T, L>,
    rowDataType: "text" | "number" | undefined = undefined,
    highlightStatus: "highlighted" | "normal" = "normal",
  ): AutoRenderedSheetBuilder<T, L> {
    const boundReferenceInColumn = this.referenceInColumn.bind(this);

    const rowNumber = this.body.length + 1;
    const rowIndex = this.body.length;
    const { dataRow, confidenceRow } = rowBuilder({
      data: this.data,
      labels: this.labels,
      columnReference: boundReferenceInColumn,
      rowNumber,
    });
    dataRow.rowDataType = rowDataType;

    this.body.push(dataRow);
    this.confidenceBody.push(confidenceRow);
    if (highlightStatus === "highlighted") {
      this.highlightedRowIndexes = [...this.highlightedRowIndexes, rowIndex];
    }
    return this;
  }

  asTypedRows(): RowWithType[] {
    return this.body;
  }

  asColumns(): RawCellContent[][] {
    const columns: RawCellContent[][] = [];
    // Returning `this.body` directly will return AutoRenderedRow which is an Array of strings
    // with a sneaky rowDataType property.
    // This makes tests break in really evil ways.
    // https://stackoverflow.com/questions/64652777/jest-received-serializes-to-the-same-string/64652778
    this.body.forEach((row) => {
      const rawCellRow = row.map((cell) => cell);
      columns.push(rawCellRow);
    });
    return columns;
  }

  set highlightedRowLabels(labels: L[]) {
    this._highlightedRowLabels = labels.map((label) => Object.values(label)[0]);
  }

  get highlightedRowLabels(): string[] {
    return this._highlightedRowLabels;
  }

  get percentageRowLabels(): string[] {
    return this._percentageRowLabels;
  }
}
