import { SpreadsConfig } from "src/interfaces/SpreadsConfig/SpreadsConfig";

import {
  FIRST_TRUST_LENDER_ID,
  LOCAL_BANKER_ID,
  LOCAL_BANKER_ID2,
  VANTAGE_LENDER_ID,
} from "src/utils/constants";

export const WITH_LLM_RR_LOCAL_BANKER_ID = LOCAL_BANKER_ID;

export const spreadConfig: SpreadsConfig = {
  lenderSettings: {
    [LOCAL_BANKER_ID]: {
      analysisTypes: ["GENERAL_SPREADS", "NOI_ANALYSIS", "EXTRACT_TABLES", "FINANCIALS"],
      defaultInterestRate: 0.075,
      tempHideManualRows: true,
      useLLMForRentRoll: true,
    },
    [LOCAL_BANKER_ID2]: {
      analysisTypes: ["GENERAL_SPREADS", "NOI_ANALYSIS", "EXTRACT_TABLES", "FINANCIALS"],
      defaultInterestRate: 0.075,
      tempHideManualRows: true,
      useLLMForRentRoll: false,
    },
    [VANTAGE_LENDER_ID]: {
      analysisTypes: ["GENERAL_SPREADS"],
      defaultInterestRate: 0.075,
      tempHideManualRows: false,
      useLLMForRentRoll: false,
    },
    [FIRST_TRUST_LENDER_ID]: {
      analysisTypes: ["GENERAL_SPREADS", "NOI_ANALYSIS"],
      defaultInterestRate: 0.075,
      tempHideManualRows: false,
      useLLMForRentRoll: false,
    },
  },
};

export default spreadConfig;
