import { Form1040 } from "src/interfaces/TaxFormData/Form1040";
import { Form1065 } from "src/interfaces/TaxFormData/Form1065";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { Form1120 } from "src/interfaces/TaxFormData/Form1120";
import { TaxFormData, TaxFormYear } from "src/interfaces/TaxFormData/TaxFormData";
import { RenderedWorkflow } from "src/classes/RenderedDocuments/RenderedWorkflow";
import type { ColDef } from "ag-grid-community";
import { BoundingBoxContext, GridState } from "src/classes/GridState";
import { ExtractableDocumentType } from "src/Enums/ExtractableDocumentType";
import { PersonalCashFlowData } from "src/interfaces/FinancialFormData/PersonalCashFlow";
import { Form1120sIncomeStatementGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sIncomeStatementGrouped";
import { ScheduleK1 } from "src/interfaces/TaxFormData/schedules/ScheduleK1";
import { ScheduleK1Rendered } from "src/classes/RenderedDocuments/ScheduleK1Rendered";
import { TabName } from "src/redux/reducers/types";
import { Corporation, Person } from "src/interfaces/TaxFormData/common";
import { ScheduleCBreakoutRendered } from "src/classes/RenderedDocuments/ScheduleCBreakoutRendered";
import { ScheduleEBreakoutRendered } from "src/classes/RenderedDocuments/ScheduleEBreakoutRendered";
import { capitalizeFirstLetterOfEachWord } from "src/utils/helpers";
import { Form1120sBalanceSheetGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sBalanceSheetGrouped";
import { Form1120sCashFlowGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sCashFlowGrouped";
import { Form1120sRatiosGrouped } from "../Form1120s/Form1120sRatiosGrouped";
import { Form1065BalanceSheetGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065BalanceSheetGrouped";
import { Form1065CashFlowGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065CashFlowGrouped";
import { Form1065IncomeStatementGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065IncomeStatementGrouped";
import { Form1065RatiosGrouped } from "../Form1065/Form1065RatiosGrouped";
import { HoverInfo } from "src/classes/RenderedDoc";
import { Form8825BreakoutRendered } from "src/classes/RenderedDocuments/Form8825BreakoutRendered";
import {
  Form1040Grouped,
  Form1040WithTabNames,
} from "src/classes/RenderedDocuments/Form1040/Form1040Grouped";
import {
  buildGlobalCashFlow,
  Form1040RollUpAndTabName,
  Form1065CashFlowAndTabName,
  Form1120sCashFlowAndTabName,
} from "src/classes/RenderedDocuments/GlobalCashFlow/GlobalCashFlow";
import { ContextedBy } from "src/backend/services/ocr/extractors/ContextedBy";
import { ExtractOutput } from "src/backend/services/ocr/spread-data-aggregation/strategy/data-aggregation-strategy";
import { RenderableWithConfidence } from "./TaxFormWithConfidence";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { RenderableBase } from "src/classes/RenderableBase";
import { sanitizeTabName } from "src/classes/RenderedDocuments/utils";

export type GeneralSpreadsData = {
  [key: TabName]: {
    output: TaxFormData;
    source: ExtractableDocumentType;
    geometry?: ContextedBy<TaxFormData, BoundingBoxContext>;
    confidence?: ContextedBy<TaxFormData, number>;
  };
};

export type TabGroupName = "1040" | "1065" | "1120S" | "1120" | "K1" | "Legacy" | "Global";
export type TabGroup = {
  tabName: TabName;
  group: TabGroupName;
  tabType: TabType;
};

export enum TabTypeEnum {
  PCF_FOR_YEAR = "PCF for Year",
  K1S_FOR_YEAR = "K-1s for Year",
  PCF_ALL_YEARS = "PCF All Years",
  SCHEDULE_C_BREAKOUT = "Schedule C Breakout",
  SCHEDULE_E_BREAKOUT = "Schedule E Breakout",
  BUSINESS_IS_ALL_YEARS = "Business IS All Years",
  BUSINESS_BS_ALL_YEARS = "Business BS All Years",
  BUSINESS_CASH_FLOW_ALL_YEARS = "Business Cash Flow All Years",
  BUSINESS_RATIOS_ALL_YEARS = "Business Ratios All Years",
  FORM_8825_BREAKOUT = "Form 8825 Breakout",
  GLOBAL_CASH_FLOW = "Global Cash Flow",
}

type TabType = TabTypeEnum[keyof TabTypeEnum];

/*
type TabType =
  | "PCF for Year"
  | "K-1s for Year"
  | "PCF All Years"
  | "Schedule C Breakout"
  | "Schedule E Breakout"
  | "Business IS All Years"
  | "Business BS All Years"
  | "Business Cash Flow All Years"
  | "Business Ratios All Years"
  | "Form 8825 Breakout"
  | "Global Cash Flow";
  */

export class RenderedGeneralSpreads extends RenderedWorkflow {
  colDefs: Map<TabName, ColDef<any, any>[]> = new Map();
  gridStates: Map<TabName, GridState> = new Map();
  confidences: Map<TabName, RawConfidenceContent[][]> = new Map();
  tabGroups: TabGroup[] = [];
  hoverInfoData: Map<TabName, HoverInfo[][]> = new Map();

  form1040s: RenderableWithConfidence<Form1040>[] = [];
  form1065s: Form1065[] = [];
  form1120Ss: RenderableWithConfidence<Form1120S>[] = [];
  form1120: Form1120[] = [];
  formk1: ScheduleK1[] = [];
  legacyPersonalData: PersonalCashFlowData[] = [];

  get tabs(): TabName[] {
    return Array.from(this.gridStates.keys());
  }

  constructor(public generalSpreads: GeneralSpreadsData) {
    super();
    Object.values(generalSpreads).forEach((extract) => this.placeForm(extract as ExtractOutput));
    const form1040Rollups = this.process1040();
    const form1065CashFlows = this.process1065();
    const form1120sCashFlows = this.process1120s();
    this.processGlobalCashFlow(form1040Rollups, form1065CashFlows, form1120sCashFlows);
    this.processScheduleK1();
    this.process8825();
  }

  private placeForm(extract: ExtractOutput) {
    const { output, source, confidence, geometry } = extract;
    if (source === ExtractableDocumentType.SCHEDULE_K1 && Array.isArray(output)) {
      const k1s = output as ScheduleK1[];
      this.formk1.push(...k1s);
    }

    const taxFormData = output as TaxFormData;

    switch (taxFormData.form) {
      case "1040": {
        const renderable = taxFormData as Form1040;
        const res = {
          renderable,
          confidence: confidence as ContextedBy<Form1040, number>,
        };
        this.form1040s.push(res);
        if (renderable.relatedK1s) {
          this.formk1.push(...renderable.relatedK1s);
        }
        return;
      }
      case "1065":
        const form1065 = taxFormData as Form1065;
        this.form1065s.push(form1065);
        if (form1065.schedules?.scheduleK1) {
          this.formk1.push(...form1065.schedules.scheduleK1);
        }
        return;
      case "1120S": {
        const renderable = taxFormData as Form1120S;
        const res = {
          renderable,
          confidence: confidence as ContextedBy<Form1120S, number>,
        };

        this.form1120Ss.push(res);

        if (renderable.schedules?.scheduleK1) {
          this.formk1.push(...renderable.schedules.scheduleK1);
        }

        return;
      }
      case "1120":
        this.form1120.push(taxFormData as Form1120);
        return;
    }
  }

  private addTab(
    tabName: TabName,
    content: RenderedContent,
    group: TabGroupName,
    tabType: TabType,
  ) {
    const { gridState, columnDefs, hoverInfos } = content;
    const confidence = content.asConfidence?.() || [];
    this.gridStates.set(tabName, gridState);
    this.colDefs.set(tabName, columnDefs);
    this.tabGroups.push({ tabName, group, tabType });
    this.hoverInfoData.set(tabName, hoverInfos);
    this.confidences.set(tabName, confidence || []);
  }

  //): rowBuilder is RowBuilder<T, L> => AutoRenderedSheetBuilder<T, L> {
  //): AutoRenderedSheetBuilder<T, L> {
  private process1040() {
    const taxpayerForms: Map<string, RenderableWithConfidence<Form1040>[]> = new Map();
    this.form1040s.forEach((form1040WithConfidence) => {
      const { renderable: form1040 } = form1040WithConfidence;
      const name = normalizeTaxpayerName(form1040.taxpayer, form1040.entityName);
      const forms = taxpayerForms.get(name) || [];
      taxpayerForms.set(name, [...forms, form1040WithConfidence]);
    });

    const form1040Rollups: Form1040RollUpAndTabName[] = [];
    taxpayerForms.forEach((wcs, taxpayer) => {
      const form1040Rollup = this.create1040Rollup(wcs, taxpayer);
      this.createScheduleCBreakout(wcs, taxpayer);
      this.createScheduleEBreakout(wcs, taxpayer);
      form1040Rollups.push(form1040Rollup);
    });
    return form1040Rollups;
  }

  private createScheduleCBreakout(wcs: RenderableWithConfidence<Form1040>[], taxpayer: string) {
    wcs.forEach((wc) => {
      const scheduleCs = wc.renderable.schedules?.scheduleC;
      if (!scheduleCs?.length) {
        return;
      }
      const scheduleCBreakout = new ScheduleCBreakoutRendered(scheduleCs);
      const tabName = this.formatTabName("Schedule C Breakout", taxpayer, wc.renderable.year);
      this.addTab(tabName, scheduleCBreakout, "1040", "Schedule C Breakout");
    });
  }

  private createScheduleEBreakout(forms: RenderableWithConfidence<Form1040>[], taxpayer: string) {
    forms.forEach((wc) => {
      const { renderable: form } = wc;
      if (
        form.schedules?.scheduleE?.properties === undefined ||
        form.schedules?.scheduleE.properties.length === 0
      ) {
        return;
      }
      const scheduleEBreakout = new ScheduleEBreakoutRendered(form.schedules?.scheduleE);
      const tabName = this.formatTabName("Schedule E Breakout", taxpayer, form.year);
      this.addTab(tabName, scheduleEBreakout, "1040", "Schedule E Breakout");
    });
  }

  private k1TotalFor1040(form: Form1040) {
    // total schedule k1 by year
    const scheduleK1Totals: Map<TaxFormYear, number> = new Map();
    this.formk1.forEach((k1) => {
      const formTaxpayer = normalizeTaxpayerName(form.taxpayer, "");
      const k1Taxpayer = normalizeTaxpayerName(k1.taxpayer, k1.entityName);
      if (k1Taxpayer.toLowerCase() === formTaxpayer.toLowerCase()) {
        const total = scheduleK1Totals.get(k1.year) || 0;
        const dist = k1.distributions || 0;
        const contrib = k1.contributions || 0;
        scheduleK1Totals.set(k1.year, total + dist - contrib);
      }
    });

    return scheduleK1Totals.get(form.year) || 0;
  }

  private create1040Rollup(
    formsWithConfidence: RenderableWithConfidence<Form1040>[],
    taxpayer: string,
  ) {
    const formsWithBreakoutTabNames = formsWithConfidence
      .sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year))
      .map((wc) => {
        const { renderable: form } = wc;
        const scheduleETabName = form.schedules?.scheduleE
          ? this.formatTabName("Schedule E Breakout", taxpayer, form.year)
          : null;
        const scheduleCTabName = form.schedules?.scheduleC?.length
          ? this.formatTabName("Schedule C Breakout", taxpayer, form.year)
          : null;
        const k1DistributionTotal = this.k1TotalFor1040(form);

        return {
          wc,
          k1DistributionTotal,
          scheduleETabName,
          scheduleCTabName,
        } as Form1040WithTabNames;
      });

    const form1040Rollup = new Form1040Grouped(formsWithBreakoutTabNames);

    const tabName = this.formatTabName("PCF All Years", taxpayer);
    this.addTab(tabName, form1040Rollup, "1040", "PCF All Years");
    return {
      tabName,
      cashFlow: form1040Rollup,
      entityName: taxpayer,
    };
  }

  private groupContextedFormsByNormalizedName<TForm extends RenderableBase>(
    forms: RenderableWithConfidence<TForm>[],
    getName: (form: TForm) => Corporation | undefined,
  ): Map<string, RenderableWithConfidence<TForm>[]> {
    const groupedForms: Map<string, RenderableWithConfidence<TForm>[]> = new Map();
    forms.forEach((formWithConfidence) => {
      const form = formWithConfidence.renderable;
      let name = normalizeCorporationName(getName(form));
      name = name
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters
        .replace(/\b(LLC|INC|CORP|LTD|CO|COMPANY)\b/gi, "") // Remove common company designators
        .trim();
      const existingForms = groupedForms.get(name) || [];
      groupedForms.set(name, [...existingForms, formWithConfidence]);
    });
    return groupedForms;
  }

  private groupFormsByNormalizedName<TForm>(
    forms: TForm[],
    getName: (form: TForm) => Corporation | undefined,
  ): Map<string, TForm[]> {
    const groupedForms: Map<string, TForm[]> = new Map();
    forms.forEach((form) => {
      let name = normalizeCorporationName(getName(form));
      name = name
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters
        .replace(/\b(LLC|INC|CORP|LTD|CO|COMPANY)\b/gi, "") // Remove common company designators
        .trim();
      const existingForms = groupedForms.get(name) || [];
      groupedForms.set(name, [...existingForms, form]);
    });
    return groupedForms;
  }

  private process1120s() {
    const sCorpForms = this.groupContextedFormsByNormalizedName(
      this.form1120Ss,
      (form) => form.corporation,
    );
    const form1120sCashFlows: Form1120sCashFlowAndTabName[] = [];
    sCorpForms.forEach((formsWithConfidence) => {
      const forms = formsWithConfidence.map((wc) => wc.renderable);
      const firstForm = forms[0];
      let corporationName = normalizeCorporationName(firstForm.corporation);

      const incomeStatement = this.create1120sIncomeStatement(formsWithConfidence, corporationName);
      const balanceSheet = this.create1120sBalanceSheet(formsWithConfidence, corporationName);
      const cashflow = this.create1120sCashFlow(formsWithConfidence, corporationName);

      const ratiosRendered = new Form1120sRatiosGrouped(
        formsWithConfidence,
        this.formatTabName("Business BS All Years", corporationName),
        this.formatTabName("Business IS All Years", corporationName),
        this.formatTabName("Business Cash Flow All Years", corporationName),
        balanceSheet,
        incomeStatement,
        cashflow.cashFlow,
      );
      const tabName = this.formatTabName("Business Ratios All Years", corporationName);
      this.addTab(tabName, ratiosRendered, "1120S", "Business Ratios All Years");
      form1120sCashFlows.push(cashflow);
    });
    return form1120sCashFlows;
  }

  private process1065() {
    const partnershipForms = this.groupFormsByNormalizedName(
      this.form1065s,
      (form) => form.partnership,
    );
    const form1065CashFlows: Form1065CashFlowAndTabName[] = [];
    partnershipForms.forEach((forms) => {
      const firstForm = forms[0];
      let corporationName = normalizeCorporationName(firstForm.partnership);

      const incomeStatement = this.create1065IncomeStatement(forms, corporationName);
      const balanceSheet = this.create1065BalanceSheet(forms, corporationName);
      const cashFlow = this.create1065CashFlow(forms, corporationName);
      form1065CashFlows.push(cashFlow);

      const ratiosRendered = new Form1065RatiosGrouped(
        forms,
        this.formatTabName("Business BS All Years", corporationName),
        this.formatTabName("Business IS All Years", corporationName),
        this.formatTabName("Business Cash Flow All Years", corporationName),
        balanceSheet,
        incomeStatement,
        cashFlow.cashFlow,
      );
      const tabName = this.formatTabName("Business Ratios All Years", corporationName);
      this.addTab(tabName, ratiosRendered, "1065", "Business Ratios All Years");
    });
    return form1065CashFlows;
  }

  processGlobalCashFlow(
    form1040Rollups: Form1040RollUpAndTabName[],
    form1065CashFlows: Form1065CashFlowAndTabName[],
    form1120sCashFlows: Form1120sCashFlowAndTabName[],
  ) {
    const globalCashFlow = buildGlobalCashFlow(
      form1120sCashFlows,
      form1065CashFlows,
      form1040Rollups,
    );
    const tabName = "Global Cash Flow" as TabName;
    this.addTab(tabName, globalCashFlow, "Global", "Global Cash Flow");
  }

  private create1065IncomeStatement(forms: Form1065[], corporation: string) {
    const rendered = new Form1065IncomeStatementGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName("Business IS All Years", corporation);
    this.addTab(
      tabName,
      rendered,

      "1065",
      "Business IS All Years",
    );
    return rendered;
  }

  private create1065BalanceSheet(forms: Form1065[], corporation: string) {
    const rendered = new Form1065BalanceSheetGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName("Business BS All Years", corporation);
    this.addTab(
      tabName,
      rendered,

      "1065",
      "Business BS All Years",
    );
    return rendered;
  }

  private create1065CashFlow(forms: Form1065[], corporation: string) {
    const rendered = new Form1065CashFlowGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName("Business Cash Flow All Years", corporation);
    this.addTab(tabName, rendered, "1065", "Business Cash Flow All Years");
    return {
      tabName,
      cashFlow: rendered,
      entityName: corporation,
    };
  }

  private create1120sIncomeStatement(
    forms: RenderableWithConfidence<Form1120S>[],
    corporation: string,
  ) {
    const rendered = new Form1120sIncomeStatementGrouped(forms);
    const tabName = this.formatTabName("Business IS All Years", corporation);
    this.addTab(tabName, rendered, "1120S", "Business IS All Years");
    return rendered;
  }

  private create1120sBalanceSheet(
    forms: RenderableWithConfidence<Form1120S>[],
    corporation: string,
  ): Form1120sBalanceSheetGrouped {
    const rendered = new Form1120sBalanceSheetGrouped(
      forms.sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year)),
    );
    const tabName = this.formatTabName("Business BS All Years", corporation);
    this.addTab(tabName, rendered, "1120S", "Business BS All Years");
    return rendered;
  }

  private create1120sCashFlow(forms: RenderableWithConfidence<Form1120S>[], corporation: string) {
    const rendered = new Form1120sCashFlowGrouped(
      forms.sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year)),
    );
    const tabName = this.formatTabName("Business Cash Flow All Years", corporation);
    this.addTab(tabName, rendered, "1120S", "Business Cash Flow All Years");
    return {
      tabName,
      cashFlow: rendered,
      entityName: corporation,
    };
  }

  private process8825() {
    this.form1065s.forEach((form, index) => {
      if (form.form8825) {
        const name = form?.partnership?.name ?? `Partnership ${index + 1}`;
        const rendered = new Form8825BreakoutRendered(form.form8825);
        const tabName = this.formatTabName("Form 8825 Breakout", name, form.form8825.year);
        this.addTab(tabName, rendered, "1065", "Form 8825 Breakout");
      }
    });
    this.form1120Ss.forEach((formWithConfidence, index) => {
      const form = formWithConfidence.renderable;
      if (form.form8825) {
        const name = form?.corporation?.name ?? `Corporation ${index + 1}`;
        const rendered = new Form8825BreakoutRendered(form.form8825);
        const tabName = this.formatTabName("Form 8825 Breakout", name, form.form8825.year);
        this.addTab(tabName, rendered, "1120S", "Form 8825 Breakout");
      }
    });
  }

  private processScheduleK1() {
    const taxpayerYears: String[] = [];
    this.formk1.forEach((form) => {
      if (!taxpayerYears.includes(form.year)) {
        const taxpayer = normalizeTaxpayerName(form.taxpayer, form.entityName);
        taxpayerYears.push(`${form.year}-${taxpayer}`);
      }
    });

    taxpayerYears.sort((a, b) => (a > b ? 1 : -1));
    for (const taxpayerYear of taxpayerYears) {
      const taxPayersK1ThisYear = this.formk1.filter((k1) => {
        const taxpayer = normalizeTaxpayerName(k1.taxpayer, k1.entityName);
        return taxpayerYear === `${k1.year}-${taxpayer}`;
      });
      const [taxformyear, entityName, ..._parts] = taxpayerYear.split("-");
      const tabName = this.formatTabName("K-1s for Year", entityName, taxformyear as TaxFormYear);
      const rendered = new ScheduleK1Rendered(taxPayersK1ThisYear, taxpayerYear as TaxFormYear);
      this.addTab(tabName, rendered, "K1", "K-1s for Year");
    }
  }

  public formatTabName(tabType: TabType, entityName?: string, year?: TaxFormYear): TabName {
    switch (tabType) {
      case "PCF for Year": {
        if (!year) {
          throw new Error("Year is required for PCF");
        }
        return sanitizeTabName(`${entityName} - ${year}`) as TabName;
      }
      case "K-1s for Year": {
        if (!year) {
          throw new Error("Year is required for K-1s");
        }
        return sanitizeTabName(`K-1 - ${year} - ${entityName}`) as TabName;
      }
      case "PCF All Years": {
        return sanitizeTabName(`${entityName}`) as TabName;
      }
      case "Schedule C Breakout": {
        return sanitizeTabName(`Schedule C - ${year} - ${entityName}`) as TabName;
      }
      case "Schedule E Breakout": {
        if (!year) {
          throw new Error("Year is required for Schedule E");
        }
        return sanitizeTabName(`Schedule E - ${year} - ${entityName}`) as TabName;
      }
      case "Business IS All Years": {
        return sanitizeTabName(`Bus IS - ${entityName}`) as TabName;
      }
      case "Business BS All Years": {
        return sanitizeTabName(`Bus BS - ${entityName}`) as TabName;
      }
      case "Business Cash Flow All Years": {
        return sanitizeTabName(`Bus CF - ${entityName}`) as TabName;
      }
      case "Business Ratios All Years": {
        return sanitizeTabName(`Bus Ratios - ${entityName}`) as TabName;
      }
      case "Form 8825 Breakout": {
        if (!year) {
          throw new Error(`Year is required for ${tabType}`);
        }
        return sanitizeTabName(`Form 8825 - ${year} - ${entityName}`) as TabName;
      }
      case "Global Cash Flow": {
        return "Global Cash Flow" as TabName;
      }
      default: {
        throw new Error(`Unknown tab type: ${tabType}`);
      }
    }
  }
}

export function normalizeTaxpayerName(input: Person | undefined, entityName: string): string {
  if (entityName || !input || !input?.firstName || !input?.lastName) {
    return entityName;
  }
  const taxpayer = input as Person;
  const onlyFirst = taxpayer.firstName?.split(" ").shift();
  const last = taxpayer.lastName?.split(" ").pop();
  return capitalizeFirstLetterOfEachWord(`${onlyFirst} ${last}`);
}

export function normalizeCorporationName(input: Corporation | undefined): string {
  if (!input || !input.name) {
    return "";
  }

  return capitalizeFirstLetterOfEachWord(input.name.trim());
}

type RenderedContent = {
  gridState: GridState;
  columnDefs: ColDef[];
  hoverInfos: HoverInfo[][];
  asConfidence?: () => RawConfidenceContent[][];
};
