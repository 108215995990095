import { analysisTypes } from "src/page-components/investment-detail/tabs/SpreadsTab/SpreadsTabConstants";
import {
  FIRST_TRUST_LENDER_ID,
  LOCAL_BANKER_ID,
  LOCAL_BANKER_ID2,
  VANTAGE_LENDER_ID,
} from "src/utils/constants";

export const supportedLenderIds = [
  LOCAL_BANKER_ID,
  LOCAL_BANKER_ID2,
  VANTAGE_LENDER_ID,
  FIRST_TRUST_LENDER_ID,
] as const;

export type ValidAnalysis = (typeof analysisTypes)[number];

export type SupportedLenderId = (typeof supportedLenderIds)[number];

export type SpreadsConfig = {
  lenderSettings: Record<SupportedLenderId, LenderSpreadSettings>;
};

export interface LenderSpreadSettings {
  analysisTypes: ValidAnalysis[];
  defaultInterestRate: number;
  tempHideManualRows: boolean;
  useLLMForRentRoll: boolean;
}
