import produce from "immer";
import { Reducer } from "redux";
import { FeatureFlags } from "src/redux/actions/actions.constants";
import { DEFAULT_FEATURE_FLAGS, FeatureFlag } from "src/models/FeatureFlag";

export interface FeatureFlagState {
  featureFlags: FeatureFlag;
  isLoading: boolean;
}

export const initialFeatureFlagState: FeatureFlagState = {
  featureFlags: DEFAULT_FEATURE_FLAGS,
  isLoading: false,
};

export const featureFlagReducer: Reducer<FeatureFlagState> = (
  state: FeatureFlagState = initialFeatureFlagState,
  action,
) =>
  produce(state, (draft: any) => {
    const payload = action.payload as any;
    switch (action.type) {
      case FeatureFlags.SET_FEATURE_FLAGS: {
        draft.isLoading = false;
        draft.featureFlags = payload.featureFlags;
        break;
      }
      case FeatureFlags.FETCH_FEATURE_FLAGS: {
        draft.isLoading = true;
        break;
      }
    }
  });
