import { TaxFormType } from "src/interfaces/TaxFormData/TaxFormData";

export function formatNameForRentRoll(propertyName: string): string {
  return `RR - ${propertyName}`;
}

export function formatNameForSubject(propertyName: string): string {
  return `SUBJ - ${propertyName}`;
}

export function getSourceFromFormType(formType: TaxFormType | undefined | null): string {
  switch (formType) {
    case "1040":
      return "Personal Tax Return";
    case "1065":
      return "Partnership Tax Return (1065)";
    case "1120S":
      return "S-Corp Tax Return (1120-S)";
    default:
      return "Tax Return";
  }
}
