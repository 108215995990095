export function createAccessTrackingProxy<T extends object>(
  data: T,
  accessPaths: string[],
  parentPath = "",
): T {
  return new Proxy(data, {
    get(target, prop: string | symbol) {
      if (typeof prop === "symbol") return target[prop as keyof T];

      const path = parentPath ? `${parentPath}.${prop}` : prop;
      const value = target[prop as keyof T];

      if (typeof value === "object" && value !== null) {
        return createAccessTrackingProxy(value, accessPaths, path);
      } else {
        accessPaths.push(path);
        return value;
      }
    },
  });
}
