import { createSelector } from "@reduxjs/toolkit";
import { RawCellContent } from "hyperformula";
import { AsRowsAccType, GridRowData, colNumberToExcelCol } from "src/classes/RenderedDoc";
import { spreadSelector } from "src/redux/selectors/app.selectors";
import { GroupName, PropertyGroups, TabName } from "src/redux/reducers/types";
import { RentRollTableData } from "src/classes/RenderedDocuments/RentRoll";
import { GridState } from "src/classes/GridState";
import { mainCandidatesSelector } from "./ocr.selector";

export const gridStateToGridRowData = (gridState: GridState) => {
  return Object.entries(gridState).map(([key, value]) => {
    return {
      id: key,
      ...value.rowDataArray.reduce((acc: AsRowsAccType, cur: RawCellContent, index: number) => {
        const columnName = colNumberToExcelCol(index + 1); // Convert index to column name
        acc[columnName] = {
          value: cur,
          style: value.rowStyle,
          metadata: value.rowMetadata,
          type: index === 0 ? "text" : value.rowDataType,
          isManagedByApp: value.isManagedByApp,
        };
        return acc;
      }, {} as AsRowsAccType),
    };
  }) as GridRowData[];
};

export const tabNamesSelector = createSelector(spreadSelector, (spread) => {
  const tabNames = Object.keys(spread.gridStates || {}) as TabName[];
  const groups = spread.tabGroups || [];

  const sortedTabNames = tabNames.sort((a, b) => {
    const groupA = groups.find((grp) => grp.tabName === a)?.group ?? "Legacy";
    const groupB = groups.find((grp) => grp.tabName === b)?.group ?? "Legacy";
    return groupA.localeCompare(groupB);
  });
  return sortedTabNames;
});

export const gridRowDataSelector = createSelector(
  spreadSelector,
  tabNamesSelector,
  (spread, orderedTabNames) => {
    if (!spread.gridStates) return null;
    const gridRowDatas = orderedTabNames.reduce(
      (acc, tabName) => {
        if (spread.gridStates && spread.gridStates[tabName]) {
          acc[tabName] = gridStateToGridRowData(spread.gridStates[tabName]);
        }
        return acc;
      },
      {} as Record<TabName, GridRowData[]>,
    );
    return gridRowDatas;
  },
);

export const gridStatesSelector = createSelector(spreadSelector, (spread) => {
  return spread.gridStates;
});

export const tabGroupsSelector = createSelector(spreadSelector, (spread) => {
  return spread.tabGroups;
});

export const currentSpreadIdSelector = createSelector(spreadSelector, (spread) => {
  return spread.currentSpreadId;
});

export const columnsDefsSelector = createSelector(spreadSelector, (spread) => {
  return spread.columnsDefs;
});

export const formulaBarSelector = createSelector(spreadSelector, (spread) => {
  return spread.formulaBar;
});

export const sheetsSelector = createSelector(spreadSelector, (spread) => {
  return { rawSheets: spread.rawSheets, calculatedSheets: spread.calculatedSheets };
});

export const subjectAssetsSelector = createSelector(spreadSelector, (spread) => {
  return spread.subjectAssets as RentRollTableData[] | null;
});

export const rawSheetsSelector = createSelector(spreadSelector, (spread) => {
  return spread.rawSheets;
});

export const calculatedSheetsSelector = createSelector(spreadSelector, (spread) => {
  return spread.calculatedSheets;
});

export const spreadUserStateSelector = createSelector(spreadSelector, (spread) => {
  return spread.userState;
});

export const analysisTypeSelector = createSelector(spreadSelector, (spread) => {
  return spread.analysisType;
});

export const hoverInfoSelector = createSelector(spreadSelector, (spread) => {
  return spread.hoverInfos || {};
});

export const confidenceSelector = createSelector(spreadSelector, (spread) => {
  return spread.confidences;
});

export const selectedAssetsSelector = createSelector(
  spreadSelector,
  mainCandidatesSelector,
  (spread, candidates) => {
    if (!spread.selectedAssets || spread.selectedAssets.length === 0) return candidates;
    return spread.selectedAssets.filter((asset) => candidates.includes(asset));
  },
);

export const colSpansSelector = createSelector(spreadSelector, (spread) => {
  return spread.colSpans;
});

export const INDIVIDUAL_PROPERTIES = "Individual Properties" as GroupName;
export const propertyGroupsSelector = createSelector(
  spreadSelector,
  selectedAssetsSelector,
  (spread, selectedAssets) => {
    const existingProperties = new Set(
      Object.values(spread.propertyGroups).flatMap((group) => group.properties),
    );

    const missingProperties = selectedAssets.filter(
      (property) => !existingProperties.has(property),
    );

    const individualPropertiesGroup = {
      properties: [
        ...(spread.propertyGroups[INDIVIDUAL_PROPERTIES]?.properties || []).filter(
          // only include properties that have been selected
          (property) => selectedAssets.includes(property),
        ),
        ...missingProperties,
      ],
    };

    const otherGroups = Object.entries(spread.propertyGroups)
      .filter(([groupName]) => groupName !== INDIVIDUAL_PROPERTIES)
      .reduce((acc, [groupName, group], index) => {
        acc[groupName as GroupName] = {
          properties: group.properties.filter((property) => selectedAssets.includes(property)),
          index: group.index ?? index,
        };
        return acc;
      }, {} as PropertyGroups);

    const newPropertyGroups = {
      ...otherGroups,
      [INDIVIDUAL_PROPERTIES]: individualPropertiesGroup,
    };

    return newPropertyGroups;
  },
);
