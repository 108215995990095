import type { RawCellContent } from "hyperformula";
import { AutoRendered } from "src/classes/AutoRendered";
import { GridRow, GridState } from "src/classes/GridState";
import { guessRowDataType } from "src/classes/RenderedDoc";

// This is specifying that GenericPDFTableData has all the properties of PropertyParts,
// some of which are required, and (the rest of which are optional)
export type AnyData = Record<string, any> | Record<string, any>[];

export class GenericKVTable extends AutoRendered<AnyData> {
  asColumns(): RawCellContent[][] {
    if (Array.isArray(this.underlying)) {
      const keys = Object.keys(this.underlying[0]).filter((key) => key !== "extractContext");
      const cells: RawCellContent[][] = new Array(keys.length).fill(0).map(() => []);

      cells.forEach((cell, index) => {
        cell.push(keys[index]);
        this.underlying.forEach((row: Record<string, any>) => {
          cell.push(row[keys[index]]);
        });
      });
      return cells;
    } else {
      return Object.entries(this.underlying);
    }
  }

  get defaultRowLabels(): string[] {
    return Object.keys(this.underlying);
  }

  get highlightedRowLabels() {
    return [];
  }
  get percentageRowLabels() {
    return this.defaultRowLabels.filter((label) => {
      return label.includes("Percentage");
    });
  }

  constructor(public underlying: AnyData) {
    super(underlying);
  }

  get initialGridState(): GridState {
    const gridState: GridState = Object.fromEntries(
      Object.entries(this.underlying).map(([propertyName, value], index) => {
        const cells: RawCellContent[] = [propertyName, value?.toString() ?? ""];
        const gridRow: GridRow = {
          rowDataArray: cells,
          rowDataType: guessRowDataType(cells),
          rowStyle: "standard",
          isManagedByApp: false,
          index,
        };
        return [index, gridRow];
      }),
    );
    return gridState;
  }
}
