import z from "zod";

// In Zod v1.7, schemas could be used as type guards. From their docs:
//
// You can also use a Zod schema as a type guard using the schema's .check() method, like so:
// <pre>
// const stringSchema = z.string();
// const blob: any = 'Albuquerque';
// if (stringSchema.check(blob)) {
//   // blob is now of type `string`
//   // within this if statement
// }
// </pre>
//
// This seems to have been removed.
// check() adds this functionality back.
export function check<M extends z.ZodType>(model: M, data: unknown): data is z.infer<M> {
  return model.safeParse(data).success;
}
