import { lowerCase, startCase } from "lodash";
import moment from "moment";
import { SpreadsDocumentType } from "./SpreadsDocumentType";
import { SupportedLenderId } from "src/interfaces/SpreadsConfig/SpreadsConfig";
import { spreadConfig } from "src/spreads-config";

export function manualFlowToAnalysisType(manualFlow: ManualWorkFlowEnum): AnalysisType {
  switch (manualFlow) {
    case ManualWorkFlowEnum.GENERAL_MIDDLE_MARKET:
      return AnalysisType.GENERAL_MIDDLE_MARKET;
  }
}

export const analysisTypeToManualFlow = (analysisType: AnalysisType): ManualWorkFlowEnum => {
  switch (analysisType) {
    case AnalysisType.GENERAL_MIDDLE_MARKET:
      return ManualWorkFlowEnum.GENERAL_MIDDLE_MARKET;
    default:
      throw new Error(`Analysis type: ${analysisType} is not manual`);
  }
};

export type PageDistribution = {
  [key in string]: SpreadsDocumentType[];
};

export type excelData = string[][];

export enum AnalysisType {
  EMPTY = "",
  BUSINESS_CASH_FLOW = "BUSINESS_CASH_FLOW",
  PERSONAL_CASH_FLOW = "PERSONAL_CASH_FLOW",
  NOI_ANALYSIS = "NOI_ANALYSIS",
  GENERAL_MIDDLE_MARKET = "GENERAL_MIDDLE_MARKET",
  EXTRACT_TABLES = "EXTRACT_TABLES",
  EXTRACT_KEY_VALUES = "EXTRACT_KEY_VALUES",
  GENERAL_SPREADS = "GENERAL_SPREADS",
  FINANCIALS = "FINANCIALS",
}

export const analysisTypes = [
  "BUSINESS_CASH_FLOW",
  "PERSONAL_CASH_FLOW",
  "NOI_ANALYSIS",
  "GENERAL_MIDDLE_MARKET",
  "EXTRACT_TABLES",
  "EXTRACT_KEY_VALUES",
  "GENERAL_SPREADS",
  "FINANCIALS",
] as const;

export type AnalysisItem = {
  label: string;
  value: AnalysisType;
  disabled: boolean;
};
export type AnalysisOption = AnalysisItem | "break";

export const AnalysisOptions = (lenderId: number | null): AnalysisOption[] => {
  if (lenderId === null) {
    return [];
  }
  const toOption = (analysisType: (typeof analysisTypes)[number]): AnalysisItem => {
    return {
      label: analysisTypeToLabel(analysisType as AnalysisType),
      value: analysisType as AnalysisType,
      disabled: false,
    };
  };
  const settings = spreadConfig.lenderSettings[lenderId as SupportedLenderId] || [];
  return settings.analysisTypes.map(toOption);
};

const analysisTypeToLabel = (analysisType: AnalysisType): string => {
  switch (analysisType) {
    case AnalysisType.BUSINESS_CASH_FLOW:
      return "Business Cash Flow";
    case AnalysisType.PERSONAL_CASH_FLOW:
      return "Personal Cash Flow";
    case AnalysisType.NOI_ANALYSIS:
      return "NOI Analysis";
    case AnalysisType.GENERAL_MIDDLE_MARKET:
      return "General Middle Market";
    case AnalysisType.EXTRACT_KEY_VALUES:
      return "Extract Key Values from Company Prepared";
    case AnalysisType.EXTRACT_TABLES:
      return "Company Prepared";
    case AnalysisType.GENERAL_SPREADS:
      return "General Spreads";
    case AnalysisType.FINANCIALS:
      return "Financials";
    case AnalysisType.EMPTY:
      return "";
  }
};

export const documentOptions = [
  {
    label: startCase(lowerCase(SpreadsDocumentType.CORPORATE_TAX_RETURN)),
    value: SpreadsDocumentType.CORPORATE_TAX_RETURN,
  },
  {
    label: startCase(lowerCase(SpreadsDocumentType.FINANCIAL_STATEMENT)),
    value: SpreadsDocumentType.FINANCIAL_STATEMENT,
  },
  {
    label: startCase(lowerCase(SpreadsDocumentType.MULTI_PROPERTY_RENTROLL)),
    value: SpreadsDocumentType.MULTI_PROPERTY_RENTROLL,
  },
  {
    label: startCase(lowerCase(SpreadsDocumentType.RENT_ROLL)),
    value: SpreadsDocumentType.RENT_ROLL,
  },
  {
    label: startCase(lowerCase(SpreadsDocumentType.PERSONAL_TAX_RETURN)),
    value: SpreadsDocumentType.PERSONAL_TAX_RETURN,
  },
  {
    label: startCase(lowerCase(SpreadsDocumentType.BALANCE_SHEET)),
    value: SpreadsDocumentType.BALANCE_SHEET,
  },
  {
    label: startCase(lowerCase(SpreadsDocumentType.INCOME_STATEMENT)),
    value: SpreadsDocumentType.INCOME_STATEMENT,
  },
];

export enum ManualWorkFlowEnum {
  GENERAL_MIDDLE_MARKET = AnalysisType.GENERAL_MIDDLE_MARKET,
}

export const LenderIdToWorkflow: Record<number, ManualWorkFlowEnum> = {
  4: ManualWorkFlowEnum.GENERAL_MIDDLE_MARKET,
};

// TODO: check the current date and modify this array
export const defaultYears: string[] = [
  moment().subtract("1", "year").year().toString(),
  moment().subtract("2", "year").year().toString(),
  moment().subtract("3", "year").year().toString(),
];

export const SpreadDefaultYearsDocuments = defaultYears.map((year) => {
  return { year: year, type: "" };
});

export const SpreadsInitialFormValues = {
  analysis: AnalysisType.NOI_ANALYSIS,
  documents: SpreadDefaultYearsDocuments,
};

export const WhiteColor = "#FFFFFF";
export const TableHightlightColor = "#F7F9FB";
export const TableHighlightBlueColor = "#E7EDF4";

export const SheetHeight = 500;
export const SheetRowHeight = 45;
export const SheetHeaderHeight = 60;
export const SheetSuppressRowHoverHighlight = true;
export const SheetEnableCellExpressions = true;
export const SheetEnableCellChangeFlash = true;
