import type { RawCellContent } from "hyperformula";
import { AutoRendered, autoRenderedTextRow } from "src/classes/AutoRendered";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import {
  build1120sBalanceSheet,
  Form1120sBalanceSheetRowHeadings,
} from "src/classes/RenderedDocuments/Form1120s/Form1120sBalanceSheet";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";

export class Form1120sBalanceSheetGrouped extends AutoRendered<
  RenderableWithConfidence<Form1120S>[]
> {
  private rendered: AutoRendered<Form1120S>[] = [];
  constructor(data: RenderableWithConfidence<Form1120S>[]) {
    super(data.sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year)));
    this.process();
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  private process() {
    this.underlying.forEach((form, i) => {
      const startingColumn = this.getColumnLetter(i);
      const singleRendered = build1120sBalanceSheet(form, startingColumn, 1);
      this.rendered.push(singleRendered);
    });

    this.rendered.sort((a, b) => parseInt(a.underlying.year) - parseInt(b.underlying.year));
    this.setConfidenceBody();
  }

  setConfidenceBody(): void {
    const header = [0, ...Array(this.rendered.length).fill(null)];
    const body: RawConfidenceContent[][] = [];
    this.rendered.forEach((rendered, i) => {
      const singleRendered = rendered.asConfidence();
      singleRendered.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });
    this.confidenceBody = [header, ...body];
  }

  asColumns(): RawCellContent[][] {
    const data = this.underlying.sort(
      (a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year),
    );
    const header = autoRenderedTextRow(["Year", ...data.map((r) => r.renderable.year)]);
    const body: RawCellContent[][] = [];

    this.rendered.forEach((singleRendered, i) => {
      const formColumns = singleRendered.asColumns();
      formColumns.forEach((row, rowIndex) => {
        if (!body[rowIndex]) {
          body[rowIndex] = [row[0]];
        }
        body[rowIndex].push(row[1]);
      });
    });

    return [header, ...body];
  }

  get highlightedRowLabels(): string[] {
    const h = Form1120sBalanceSheetRowHeadings;
    return [
      "Year",
      h.Assets,
      h.TotalCurrentAssets,
      h.TotalNonCurrentAssets,
      h.TotalAssets,
      h.LiabilitiesAndShareholdersEquity,
      h.TotalCurrentLiabilities,
      h.TotalNonCurrentLiabilities,
      h.TotalLiabilities,
      h.TotalShareholdersEquity,
      h.TotalLiabilitiesAndEquity,
    ];
  }
  get percentageRowLabels(): string[] {
    return [];
  }

  public indexOfHeader(header: keyof typeof Form1120sBalanceSheetRowHeadings): number {
    const heading = Form1120sBalanceSheetRowHeadings[header];
    return this.asColumns().findIndex((row) => row[0] === heading) + 1;
  }
}
