import { AutoRenderedSheetBuilder } from "./AutoRenderedSheetBuilder";
import { AutoRendered } from "../AutoRendered";
import { RawCellContent } from "hyperformula";
import { RowWithType } from "../RowWithType";
import { NOIGroupedAsset } from "./NOIGroupedAsset";

export const NOIGroupedAssetsHeadings = {
  Source: "Source",
  Entity: "Entity",
  Property: "Property",
  Year: "Year",
  GrossRents: "Gross Rents",
  TotalExpenses: "Total Expenses",
  NetIncome: "Net Income",
  Interest: "Interest",
  Depreciation: "Depreciation",
  Amortization: "Amortization",
  NetOperatingIncome: "Net Operating Income (NOI)",
  DebtServiceIndividual: "Debt Service (Individual)",
  DebtServiceShared: "Debt Service (Shared)",
  ExcessCashFlow: "Excess Cash Flow",
  DebtServiceCoverageRatio: "Debt Service Coverage Ratio",
  TargetDSCR: "Target DSCR",
};

function buildNOIGroupedAssetsTable(
  data: NOIGroupedAsset,
  startingColumn: string,
  startingRow: number,
) {
  const builder = new AutoRenderedSheetBuilder(
    data,
    NOIGroupedAssetsHeadings,
    startingRow,
    startingColumn,
  );

  builder
    .addRow(({ data, labels }) => [labels.Source, `${data.year} ${data.source}`], "text", "normal")
    .addRow(({ data, labels }) => [labels.Entity, data.entityName ?? ""], "text")
    .addRow(({ data, labels }) => [labels.Property, data.propertyName ?? ""], "text")
    .addRow(({ data, labels }) => [labels.GrossRents, data.grossRents ?? 0], "number")
    .addRow(({ data, labels }) => [labels.TotalExpenses, data.totalExpenses], "number")
    .addRow(({ data, labels }) => [labels.Interest, data.interest], "number")
    .addRow(({ data, labels }) => [labels.Depreciation, data.depreciation], "number")
    .addRow(({ data, labels }) => [labels.Amortization, data.amortization.statementValue], "number")
    .addRow(
      ({ labels, columnReference }) => [
        labels.NetOperatingIncome,
        `=${columnReference(labels.GrossRents)}-${columnReference(labels.TotalExpenses)}+${columnReference(labels.Interest)}+${columnReference(labels.Depreciation)}+${columnReference(labels.Amortization)}`,
      ],
      "number",
    );

  return builder;
}

export class NOIGroupedAssetsTable extends AutoRendered<NOIGroupedAsset[]> {
  private defaultTargetDSCR = 1.25;

  asColumns(): RawCellContent[][] {
    if (this.underlying.length === 0) {
      return [];
    }
    const startingRow = 0;
    const data = this.underlying.sort((a, b) => Number(a.year) - Number(b.year));
    const body: RawCellContent[][] = [];

    const renderedForms = data.map((form, i) => {
      const startingColumn = this.getColumnLetter(i + 1);
      return buildNOIGroupedAssetsTable(form, startingColumn, startingRow);
    });

    const allLabels = new Set<string>();
    renderedForms.forEach((rendered) => {
      rendered.body.forEach((row) => {
        allLabels.add(row[0] as string);
      });
    });
    const labels = Array.from(allLabels);

    labels.forEach((label, i) => {
      const rowIndex = i + 1;
      const row: RowWithType = [label];
      if (
        label === NOIGroupedAssetsHeadings.Source ||
        label === NOIGroupedAssetsHeadings.Entity ||
        label === ""
      ) {
        row.push("");
      } else if (label === NOIGroupedAssetsHeadings.Property) {
        row.push("Combined All Properties");
      } else {
        row.push(
          `=SUM(${this.getColumnLetter(1)}${rowIndex}:${this.getColumnLetter(data.length)}${rowIndex})`,
        );
      }
      renderedForms.forEach((rendered) => {
        const matchingRow = rendered.body.find((r) => r[0] === label);
        if (matchingRow) {
          row.push(matchingRow[1]);
          row.rowDataType = matchingRow.rowDataType;
        } else {
          row.push(null);
        }
      });
      body.push(row);
    });

    // now for shared totals rows
    const defaultTargetDSCR = this.defaultTargetDSCR;
    const noiRowIndex = body.length;
    const debtServiceIndividualRowIndex = body.length + 1;
    const debtServiceSharedRowIndex = body.length + 2;
    const col = this.getColumnLetter.bind(this);
    const addColSpan = (cellIdentifier: string, colSpan: number) => {
      this.colSpans[cellIdentifier] = colSpan;
    };
    body.push(debtServiceRow());
    body.push(debtServiceSharedRow());
    body.push(excessCashFlowRow());
    body.push(dscrRow());
    body.push(targetDscrRow());
    body.push(passOrFailRow());

    return [...body];

    function debtServiceRow() {
      const currentRow = body.length + 1;
      const debtServiceRow: RowWithType = [
        NOIGroupedAssetsHeadings.DebtServiceIndividual,
        `=SUM(C${currentRow}:${col(data.length)}${currentRow})`,
        ...Array(data.length).fill(0),
      ];
      debtServiceRow.rowDataType = "number";
      return debtServiceRow;
    }

    function debtServiceSharedRow() {
      const currentRow = body.length + 1;
      addColSpan(`C${currentRow}`, data.length);
      const debtServiceSharedRow: RowWithType = [
        NOIGroupedAssetsHeadings.DebtServiceShared,
        `=C${currentRow}`,
        0, // the value is here and the other cells reference it
        ...Array(data.length - 1).fill(`=C${currentRow}`),
      ];
      debtServiceSharedRow.rowDataType = "number";
      return debtServiceSharedRow;
    }

    function excessCashFlowRow() {
      const excessCashFlowRow: RowWithType = [
        NOIGroupedAssetsHeadings.ExcessCashFlow,
        `=B${noiRowIndex} - B${debtServiceIndividualRowIndex} - B${debtServiceSharedRowIndex}`,
        ...Array(data.length).fill(" "),
      ];
      excessCashFlowRow.rowDataType = "number";
      return excessCashFlowRow;
    }

    function dscrRow() {
      const dscrRow: RowWithType = [
        NOIGroupedAssetsHeadings.DebtServiceCoverageRatio,
        `=B${noiRowIndex} / (B${debtServiceIndividualRowIndex} + B${debtServiceSharedRowIndex})`,
        ...Array(data.length).fill(" "),
      ];
      dscrRow.rowDataType = "number";

      return dscrRow;
    }

    function targetDscrRow() {
      const targetDscrRow: RowWithType = [
        NOIGroupedAssetsHeadings.TargetDSCR,
        defaultTargetDSCR, // could get from lender's config
        ...Array(data.length).fill(" "),
      ];
      targetDscrRow.rowDataType = "number";
      return targetDscrRow;
    }

    function passOrFailRow() {
      const passOrFailRow: RowWithType = [
        "",
        `=IF(B${body.length - 1} >= B${body.length}, "Pass", "Fail")`,
        ...Array(data.length).fill(" "),
      ];
      passOrFailRow.rowDataType = "text";
      return passOrFailRow;
    }
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  get highlightedRowLabels(): string[] {
    return [
      NOIGroupedAssetsHeadings.Source,
      NOIGroupedAssetsHeadings.Entity,
      NOIGroupedAssetsHeadings.Property,
      NOIGroupedAssetsHeadings.NetOperatingIncome,
      NOIGroupedAssetsHeadings.DebtServiceIndividual,
      NOIGroupedAssetsHeadings.DebtServiceShared,
      NOIGroupedAssetsHeadings.ExcessCashFlow,
      NOIGroupedAssetsHeadings.DebtServiceCoverageRatio,
      NOIGroupedAssetsHeadings.TargetDSCR,
    ];
  }

  get percentageRowLabels(): string[] {
    return [];
  }

  constructor(data: NOIGroupedAsset[]) {
    super(data);
  }
}
