import {
  CreateReportingSequencePayload,
  UpdateReportingSequencePayload,
} from "src/contracts/loan/document/reporting-sequence";
import { UploadDocumentResponse } from "src/models/DocumentRequest";
import { LoanDetails } from "src/models/Loan";
import { PipelineLoans } from "./actions.constants";
import { DocumentUploadStatus, ReminderStatus } from "@prisma/client";
import { PaginationParams } from "src/utils/pagination";
import { ReviewEditReportingSequence } from "src/pages/api/annual-review/index.contracts";
import { DocumentQuality } from "src/Enums/DocumentQualityEnum";

export const getPipelineLoans = (fetchNextPage?: boolean) => ({
  type: PipelineLoans.FETCH_LOANS,
  payload: { fetchNextPage },
});

export const updatePipelineSearchParams = (data: PaginationParams) => ({
  type: PipelineLoans.UPDATE_PIPELINE_SEARCH_PARAMS,
  payload: data,
});

export const setPipelineSearchParams = (data: PaginationParams) => ({
  type: PipelineLoans.SET_PIPELINE_SEARCH_PARAMS,
  payload: data,
});

export const getPipelineAnnualReviews = (fetchNextPage?: boolean) => ({
  type: PipelineLoans.FETCH_ANNUAL_REVIEWS,
  payload: {
    fetchNextPage,
  },
});

export const setPipelineLoans = (data: any) => ({
  type: PipelineLoans.SET_LOANS,
  payload: data,
});

export const setPipelineReviews = (data: any) => ({
  type: PipelineLoans.SET_REVIEWS,
  payload: data,
});

export const loanDetailsGetAction = (id: string) => {
  return {
    type: PipelineLoans.FETCH_LOAN_DETAIL,
    id,
  };
};

export const loanDetailsSetAction = (data: LoanDetails) => ({
  type: PipelineLoans.SET_LOAN_DETAIL,
  payload: data,
});

export const annualReviewDetailsGetAction = (id: string) => {
  return {
    type: PipelineLoans.FETCH_ANNUAL_REVIEW_DETAIL,
    id,
  };
};

export const annualReviewDetailsSetAction = (data: LoanDetails) => ({
  type: PipelineLoans.SET_ANNUAL_REVIEW_DETAIL,
  payload: data,
});

export const annualReviewDetailsGetActionFailed = (message: string) => {
  return {
    type: PipelineLoans.FETCH_ANNUAL_REVIEW_DETAIL_FAILED,
    payload: { message },
  };
};

export const createDocumentRequestAction = (
  loanId: number,
  entityId: number,
  documentsData: {
    documentTypeId: number | null;
    documentTitle: string | null;
    documentYear: string | null;
    documentQuarter?: string;
    documentMonth?: string;
    documentQuality?: DocumentQuality;
    isReview: boolean;
    documentPeriodJson?: string;
  }[],
) => {
  return {
    type: PipelineLoans.CREATE_DOCUMENT_REQUEST,
    payload: {
      loanId,
      entityId,
      documentsData,
    },
  };
};

export const createDocumentRequestActionComplete = () => {
  return {
    type: PipelineLoans.CREATE_DOCUMENT_REQUEST_COMPLETED,
    payload: {},
  };
};

export const createDocumentRequestActionFailed = () => {
  return {
    type: PipelineLoans.CREATE_DOCUMENT_REQUEST_FAILED,
    payload: {},
  };
};

export const createReportingSequenceAction = (
  input: CreateReportingSequencePayload & { loanId: number } & {
    lenderEmployeeId: number | undefined;
  },
) => {
  return {
    type: PipelineLoans.CREATE_REPORTING_SEQUENCE,
    payload: input,
  };
};

export const editReportingSequenceAction = (
  reviewId: number,
  data: ReviewEditReportingSequence,
) => {
  return {
    type: PipelineLoans.EDIT_REPORTING_SEQUENCE,
    reviewId: reviewId,
    payload: data,
  };
};

export const deleteReportingSequenceAction = (reviewId: number) => {
  return {
    type: PipelineLoans.DELETE_REPORTING_SEQUENCE,
    reviewId: reviewId,
  };
};

export const updateReportingSequenceAction = (
  input: UpdateReportingSequencePayload & { loanId: number } & {
    lenderEmployeeId: number | undefined;
  },
) => {
  return {
    type: PipelineLoans.UPDATE_REPORTING_SEQUENCE,
    payload: input,
  };
};

export const createReportingSequenceActionComplete = (newAnnualReviewId: number) => {
  return {
    type: PipelineLoans.CREATE_REPORTING_SEQUENCE_COMPLETED,
    payload: { id: newAnnualReviewId },
  };
};

export const createReportingSequenceActionFailed = () => {
  return {
    type: PipelineLoans.CREATE_REPORTING_SEQUENCE_FAILED,
    payload: {},
  };
};

export const setUploadedDocumentAction = (
  title: string,
  document: UploadDocumentResponse,
  entityId: number,
) => {
  const doc = { ...document, entityId };
  return {
    type: PipelineLoans.SET_UPLOADED_DOCUMENT,
    payload: {
      ...doc,
      title,
    },
  };
};

export const editLoanDetails = (loanId: number, data: any) => {
  return {
    type: PipelineLoans.EDIT_LOAN,
    loanId,
    payload: data,
  };
};

export const editLoanDetailsCompleted = (data: any) => {
  return {
    type: PipelineLoans.EDIT_LOAN_COMPLETED,
    payload: data,
  };
};

export const editLoanDetailsFailed = () => {
  return {
    type: PipelineLoans.EDIT_LOAN_FAILED,
  };
};

export const updateDocumentStatusAction = (input: {
  loanId: number;
  documentId: number;
  status: DocumentUploadStatus;
  isReview?: boolean;
}) => {
  console.log(
    "input.loanId, input.documentId, input.status input.isReview",
    input.loanId,
    input.documentId,
    input.status,
    input.isReview,
  );
  return {
    type: PipelineLoans.UPDATE_DOCUMENT_STATUS,
    payload: input,
  };
};

export const updateDocumentStatusActionComplete = (data: any) => ({
  type: PipelineLoans.UPDATE_DOCUMENT_STATUS_COMPLETED,
  payload: data,
});

export const updateDocumentStatusActionFailed = () => {
  return {
    type: PipelineLoans.UPDATE_DOCUMENT_STATUS_FAILED,
    payload: {},
  };
};

export const updateLoanStatusAction = (loanId: number, status: string) => ({
  type: PipelineLoans.UPDATE_LOAN_STATUS,
  payload: { loanId, status },
});

export const updateAnnualReviewStatusAction = (annualReviewId: number, status: string) => ({
  type: PipelineLoans.UPDATE_ANNUAL_REVIEW_STATUS,
  payload: { annualReviewId, status },
});

export const modifyCustomerUsersAction = (
  loanId: number,
  emails: string[],
  returnResponse: boolean,
) => ({
  type: PipelineLoans.MODIFY_CUSTOMER_USERS,
  payload: { loanId, emails, returnResponse },
});

export const updateMetadataAction = (
  docOrReminderId: string,
  loanOrReviewId: number,
  isReminder: boolean,
  isReview: boolean,
  metadata: Record<string, any>,
) => {
  return {
    type: PipelineLoans.UPDATE_METADATA,
    payload: { docOrReminderId, loanOrReviewId, isReminder, isReview, metadata },
  };
};

export const addUpdateNoteAction = (data: {
  documentRequestId: string;
  reminderId: string;
  reviewId: string;
  loanId: string;
  notes: string;
}) => {
  return {
    type: PipelineLoans.ADD_UPDATE_NOTE,
    payload: data,
  };
};

export const addUpdateNoteActionCompleted = (data: any) => {
  return {
    type: PipelineLoans.ADD_UPDATE_NOTE_COMPLETED,
    payload: { data },
  };
};

export const addUpdateNoteActionFailed = () => {
  return {
    type: PipelineLoans.ADD_UPDATE_NOTE_FAILED,
    payload: {},
  };
};

export const updateReminder = (
  reminderId: number,
  data: { notes?: string; status?: ReminderStatus },
) => {
  return {
    type: PipelineLoans.UPDATE_REMINDER,
    payload: {
      id: reminderId,
      data: data,
    },
  };
};
