import { UserAnswers } from "src/contracts/intake/answer";
import { Answer, AnswerContext } from "src/models/BorrowerIntakePage/answer";
import { baseApiService } from "./BaseApiService";

class OverviewService {
  static getInstance(): OverviewService {
    return new OverviewService();
  }

  async submitAnswer(answers: Answer[], context: AnswerContext) {
    return await baseApiService.post(
      `/api/intake/answer`,
      {
        answers,
        context,
      },
      {
        params: {
          loanId: `${context.loanId}`,
        },
      },
    );
  }

  async fetchAnswers(
    externalUserId: string,
    loanId: number,
    userType: string,
  ): Promise<UserAnswers> {
    console.log("userType", userType);
    return await baseApiService.get(`/api/intake/answer/user/${externalUserId}`, {
      params: { loanId: `${loanId}`, userType: userType },
      extras: {
        paramsSerializer: { indexes: null },
      },
    });
  }
}

export const overviewService = OverviewService.getInstance();
