import { DefaultRowLabelsSubset, Rendered, RenderedDoc } from "src/classes/RenderedDoc";
import { RentRollTableData } from "./RentRoll";
import moment from "moment";
import { formatNameForRentRoll, formatNameForSubject } from "./helpers";
import { GridState } from "src/classes/GridState";
import { ExtractableDocumentType } from "src/Enums/ExtractableDocumentType";
import {
  LoanCalculatorData,
  LoanCalculatorRendered,
} from "src/classes/RenderedDocuments/LoanCalculatorRendered";
import { SupportedLenderId } from "src/interfaces/SpreadsConfig/SpreadsConfig";

type RentRollTableDefaultRowLabels =
  | "Gross Rents"
  | "Vacancy (%)"
  | "Adjusted Rents"
  | "Management Fee"
  | "Repairs"
  | "Replacement Cost"
  | "Taxes"
  | "Insurance"
  | "Utilities"
  | "Total Expenses"
  | "Net Operating Income (NOI)"
  | "Total Debt Service"
  | "Debt Service Coverage Ratio (DSCR)"
  | "Excess/Deficit";

export class RentRollTotalTable
  extends Rendered<RentRollTableData[], RentRollTableDefaultRowLabels>
  implements RenderedDoc
{
  formattedPropertyNames: string[];
  formattedSubjectNames: string[];
  constructor(
    public rentRolls: RentRollTableData[],
    public subjectAssets: RentRollTableData[],
    public lenderId?: SupportedLenderId,
  ) {
    super(rentRolls);
    this.numberOfColumns = subjectAssets.length ? 3 : 2;
    this.formattedPropertyNames = rentRolls.map((rr) =>
      formatNameForRentRoll(rr.propertyName ?? "Property"),
    );

    this.formattedSubjectNames = subjectAssets.map((rr) =>
      formatNameForSubject(rr.propertyName ?? "Subject"),
    );
  }

  numberOfColumns: number;
  private generateMultiSheetExcelFormula(rowNumber: number): string {
    if (this?.formattedPropertyNames?.map === undefined) return "";
    const formulaParts = this.formattedPropertyNames.map(
      (propertyName) => `'${propertyName}'!B${rowNumber}`,
    );
    return `=${formulaParts.join("+")}`;
  }
  private generateCombinedFormula(rowNumber: number): string {
    const propertyFormulas = this.underlying.map(
      (roll) => `'${formatNameForRentRoll(roll.propertyName || "")}'!B${rowNumber}`,
    );
    const subjectFormulas = this.subjectAssets.map(
      (subject) => `'${formatNameForSubject(subject.propertyName || "")}'!B${rowNumber}`,
    );
    return `=SUM(${propertyFormulas.concat(subjectFormulas).join(",")})`;
  }
  get initialGridState(): GridState {
    if (!this.underlying.length) return {};

    const _sources = this.underlying.map((u) =>
      u.extractContext?.packageDetails
        .map((p) => {
          return u.extractContext.extractorType === ExtractableDocumentType.RENT_ROLL
            ? "Rent Roll"
            : "Multi Property Rentroll";
        })
        .filter((val, index, self) => self.indexOf(val) === index),
    );

    const sources = _sources.flat().filter((val, index, self) => self.indexOf(val) === index);

    const year = this.underlying[0].year;
    let index = 0;
    const excessDeficitRowIndex = 22;
    const excessDeficitRef = `B${excessDeficitRowIndex}`;
    const newDebtRowIndex = 19;
    const newDebtRef = `B${newDebtRowIndex}`;
    const loanCalculatorData: LoanCalculatorData = {
      excessCashflow: `${excessDeficitRef}`,
      lenderId: this.lenderId || 1,
      newDebt: `${newDebtRef}`,
    };
    const loanCalculator = new LoanCalculatorRendered(
      loanCalculatorData,
      excessDeficitRowIndex + 1,
    );

    return {
      // Row 1 - Source, e.g. "Rent Roll"
      source: {
        rowDataArray: ["Source", sources.join(", ")],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 2 - Entity
      entity: {
        rowDataArray: ["Entity", "Total"],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 3 - Property
      property: {
        rowDataArray: ["Property", "All Properties"],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 4 - Period Start Date
      periodStartDate: {
        rowDataArray: ["Period Start Date", moment(`01-01-${year}`).format("MM-DD-YYYY")],
        rowDataType: "date",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 5 - Period End Date
      periodEndDate: {
        rowDataArray: ["Period End Date", moment(`12-31-${year}`).format("MM-DD-YYYY")],
        rowDataType: "date",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: index++,
      },
      // Row 6 - blank
      blankRow: {
        rowDataArray: ["", ""],
        rowDataType: "text",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 7 - Gross Rents
      grossRents: {
        rowDataArray: ["Gross Rents", this.generateMultiSheetExcelFormula(7)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 8 - Occupancy Rate
      occupancyRate: {
        rowDataArray: ["Vacancy (%)", this.generateMultiSheetExcelFormula(8)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 9 - Adjusted Rents
      adjustedRents: {
        rowDataArray: ["Effective Gross Rents", "=B7 + B8"],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 10 - Annual Property Taxes
      annualPropertyTaxes: {
        rowDataArray: ["Taxes", this.generateMultiSheetExcelFormula(10)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 11 - Annual Insurance
      annualInsurance: {
        rowDataArray: ["Insurance", this.generateMultiSheetExcelFormula(11)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 12 - Utilities
      utilities: {
        rowDataArray: ["Utilities", this.generateMultiSheetExcelFormula(12)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 13 - Repair Cost
      repairsFee: {
        rowDataArray: ["Repairs", this.generateMultiSheetExcelFormula(13)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 14 - Management Fee
      managementFee: {
        rowDataArray: ["Management Fee", this.generateMultiSheetExcelFormula(14)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 15 - Replacement Cost
      replacementFee: {
        rowDataArray: ["Replacement Cost", this.generateMultiSheetExcelFormula(15)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 16 - Total Expenses - (Sum of management fee, replacement cost,
      // annual property taxes, annual insurance, and utilities)
      totalExpenses: {
        rowDataArray: ["Total Expenses", "=SUM(B10:B15)"],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 17 - Net Operating Income (Adjusted Rents - Total Expenses)
      noi: {
        rowDataArray: ["Net Operating Income (NOI)", "=B9-B16"],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 18 - Existing Debt
      existingDebt: {
        rowDataArray: ["Existing Debt", 0, 0],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 19 - New Debt = [NOI ] * 1.25 - [Existing Debt]
      newDebt: {
        rowDataArray: ["New Debt", `=B17 / 1.25 - B18`],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: index++,
      },
      // Row 20 - Total Debt Service (Existing Debt + New Debt)
      totalDebtService: {
        rowDataArray: ["Total Debt Service", "=B18+B19"],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 21 - Debt Service Coverage Ratio (NOI / Total Debt Service)
      dscr: {
        rowDataArray: ["Debt Service Coverage Ratio (DSCR)", "=B17/B20"],
        rowDataType: "ratio",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      // Row 22 - Excess/Deficit (NOI - Total Debt Service)
      excessDeficit: {
        rowDataArray: ["Excess/Deficit", "=B17-B20"],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: index++,
      },
      ...loanCalculator.initialGridState,
    } as GridState;
  }

  get defaultRowLabels(): RentRollTableDefaultRowLabels[] {
    return [
      "Gross Rents",
      "Vacancy (%)",
      "Adjusted Rents",
      "Repairs",
      "Management Fee",
      "Replacement Cost",
      "Taxes",
      "Insurance",
      "Utilities",
      "Total Expenses",
      "Net Operating Income (NOI)",
      "Total Debt Service",
      "Debt Service Coverage Ratio (DSCR)",
      "Excess/Deficit",
    ];
  }

  get highlightedRowLabels(): RentRollTableDefaultRowLabels[] {
    return [
      "Net Operating Income (NOI)",
      "Total Debt Service",
      "Debt Service Coverage Ratio (DSCR)",
      "Excess/Deficit",
    ];
  }

  percentageRowLabels: DefaultRowLabelsSubset<RentRollTableDefaultRowLabels[]> = ["Vacancy (%)"];
}
