import {
  DefaultRowLabelsSubset,
  HoverInfo,
  Rendered,
  RenderedDoc,
  RenderedMetaData,
} from "src/classes/RenderedDoc";
import { ExtractContext } from "src/backend/services/ocr/spread-data-aggregation/spread-data-aggregate";
import { RentRollTableData } from "src/classes/RenderedDocuments/RentRoll";
import { CellValue, RawCellContent } from "hyperformula";
import { GridState, RowId } from "src/classes/GridState";
import type { Prisma } from "@prisma/client";
import { TaxProperty } from "src/interfaces/TaxFormData/schedules/ScheduleE";
import { JsonValue } from "@prisma/client/runtime/library";
import { ExtractableDocumentType } from "src/Enums/ExtractableDocumentType";
import { NumberFromStatement } from "src/interfaces/TaxFormData/types/common";

export interface PropertyDetail {
  propertyName: string;
  grossRents: number;
  occupancyPercentage: number;
  managementFeePercentage: number;
  replacementCostPercentage: number;
  annualPropertyTaxes: number;
  annualInsurance: number;
  utilities: number;
  payments: number;
  year: number;
}

export interface IncomeAssetParts {
  [k: string]: RawCellContent | ExtractContext | RawCellContent[] | NumberFromStatement;
  extractContext?: ExtractContext;
  year?: number;
  grossRents?: RawCellContent;
  propertyName?: string;
  annualPropertyTaxes?: RawCellContent;
  requestedOn?: string;
  annualInsurance?: RawCellContent;
  utilities?: RawCellContent;
  repairs?: RawCellContent;
}

interface PropertyCalculations {
  get calculateNOI(): number;
  get calculateDSCR(): number;
}

export class Property implements PropertyDetail, PropertyCalculations {
  propertyName: string = "";
  grossRents: number = 0;
  occupancyPercentage: number = 0;
  managementFeePercentage: number = 0;
  replacementCostPercentage: number = 0;
  annualPropertyTaxes: number = 0;
  annualInsurance: number = 0;
  utilities: number = 0;
  payments: number = 0;
  year: number = 0;

  constructor(details: PropertyDetail) {
    Object.assign(this, details);
  }

  colLabels: string[] = ["Description", "Value"];

  get calculateNOI(): number {
    return (
      this.grossRents * (this.occupancyPercentage / 100) - // Corrected use of occupancy percentage
      (this.grossRents * (this.managementFeePercentage / 100) +
        this.grossRents * (this.replacementCostPercentage / 100) +
        this.annualPropertyTaxes +
        this.annualInsurance +
        this.utilities)
    );
  }

  get calculateDSCR(): number {
    if (this.payments === 0) {
      // Handle division by zero case, perhaps by returning a special value or throwing an error
      throw new Error("Payments cannot be zero when calculating DSCR.");
    }
    return this.calculateNOI / this.payments;
  }
}

export interface RentRollPropertyInfo {
  properties: RentRollTableData[];
}

export type PropertyDetailPersonalTaxReturn = Pick<
  PropertyDetail,
  "grossRents" | "payments" | "year"
> & {
  netIncome: number;
  totalExpenses: number;
  mortgageInterest: number;
  otherInterest: number;
  depreciation: number;
  amortization: NumberFromStatement;
  propertyName: string;
};

export interface PropertyTaxReturn extends Prisma.JsonObject {
  propertyName: string;
  grossRents: number;
  netIncome: number;
  totalExpenses: number;
  mortgageInterest: number;
  otherInterest: number;
  payments: number;
  depreciation: number;
  amortization: NumberFromStatement;
  year: number;
}

export class PropertyTaxReturn implements PropertyDetailPersonalTaxReturn, PropertyCalculations {
  static from(scheduleE: TaxProperty, entityName: string, lenderId: number): PropertyTaxReturn {
    const {
      rentsReceived,
      netIncome,
      totalExpenses,
      mortgageInterest,
      otherInterest,
      depreciation,
      amortization,
      year,
    } = scheduleE;
    const parse = (value: JsonValue | undefined) => (typeof value === "number" ? value : 0);
    const calculatedGrossRents =
      parse(netIncome) +
      parse(mortgageInterest) +
      parse(otherInterest) +
      parse(depreciation) +
      amortization.statementValue;
    const details: PropertyDetailPersonalTaxReturn = {
      propertyName: scheduleE.propertyAddress,
      grossRents: rentsReceived ? parse(rentsReceived) : calculatedGrossRents,
      totalExpenses: parse(totalExpenses),
      netIncome: parse(netIncome),
      mortgageInterest: parse(mortgageInterest),
      otherInterest: parse(otherInterest),
      payments: 0, // this is not used and TaxProperty does not have a payments field
      depreciation: parse(depreciation),
      amortization: amortization,
      year: parse(year),
    };
    const context: ExtractContext = {
      extractorType: ExtractableDocumentType.FORM_1040,
      packageDetails: [],
      metadata: {
        year: parse(year),
        entityName: entityName,
        lenderId,
      },
    };
    return new PropertyTaxReturn(details, context);
  }
  constructor(
    details: PropertyDetailPersonalTaxReturn,
    public extractContext: ExtractContext,
  ) {
    Object.assign(this, details);
  }

  propertyName: string = "";
  grossRents: number = 0;
  netIncome: number = 0;
  interest: number = 0;
  payments: number = 0;
  depreciation: number = 0;
  amortization: NumberFromStatement = {
    statementValue: 0,
  };
  year: number = 0;

  colLabels: string[] = ["Description", "Value"];

  get calculateNOI(): number {
    // Example implementation, adjust according to your logic
    return this.netIncome + this.interest + this.depreciation + this.amortization.statementValue;
  }

  get calculateDSCR(): number {
    if (this.payments === 0) throw new Error("Payments cannot be zero.");
    return this.calculateNOI / this.payments;
  }
}

export interface PersonalTaxReturnPropertyInfo {
  properties: PropertyTaxReturn[];
}
