import { FeatureFlags } from "src/redux/actions/actions.constants";

export const fetchFeatureFlags = () => {
  return {
    type: FeatureFlags.FETCH_FEATURE_FLAGS,
  };
};

export const setFeatureFlags = (payload: { featureFlags: any }) => {
  return {
    type: FeatureFlags.SET_FEATURE_FLAGS,
    payload: payload,
  };
};
