import { Rendered } from "src/classes/RenderedDoc";
import { GridState } from "src/classes/GridState";
import { TaxFormDataByYear } from "src/redux/reducers/types";
import { Form1040 } from "src/interfaces/TaxFormData/Form1040";
import { Form1065 } from "src/interfaces/TaxFormData/Form1065";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { TaxFormYear } from "src/interfaces/TaxFormData/TaxFormData";
import {
  ManyLoanCalculatorsData,
  ManyLoanCalculatorsRendered,
} from "src/classes/RenderedDocuments/LoanCalculatorRendered";
import { SupportedLenderId } from "src/interfaces/SpreadsConfig/SpreadsConfig";
import { getSourceFromFormType } from "./helpers";

type PropertyWithYearsLabel =
  | "Gross Rents"
  | ""
  | "Net Income"
  | "Total Expenses"
  | "Interest"
  | "Depreciation"
  | "Amortization"
  | "NOI"
  | "Existing Debt"
  | "New Debt"
  | "Total Debt Service"
  | "Debt Service Coverage Ratio"
  | "Excess/Deficit";

type NOITaxReturnTotalTaxForm = Form1040 | Form1065 | Form1120S;

export class NOITaxReturnTotalTable extends Rendered<
  TaxFormDataByYear<NOITaxReturnTotalTaxForm>,
  PropertyWithYearsLabel
> {
  coveredYears: TaxFormYear[];
  constructor(
    public yearlyData: TaxFormDataByYear<NOITaxReturnTotalTaxForm>,
    public tabNameFormatter: (year: string) => string,
    public numberProperties: number,
    public lenderId: SupportedLenderId = 1,
    public columnId: string = "B",
  ) {
    super(yearlyData);
    this.underlying = yearlyData;
    this.coveredYears = Object.keys(yearlyData) as TaxFormYear[];
    this.numberOfColumns = this.coveredYears.length + 1;
  }

  numberOfColumns: number;

  private generateSummaryYearSheetExcelFormula(rowNumber: number, year: string): string {
    const formattedYearName = this.tabNameFormatter(year);
    const numberOfValueColumnsInOtherSheet = this?.numberProperties;

    if (
      formattedYearName === undefined ||
      numberOfValueColumnsInOtherSheet === undefined ||
      numberOfValueColumnsInOtherSheet === 0
    )
      return "";

    const formulaParts = Array(numberOfValueColumnsInOtherSheet)
      .fill(null)
      .map((_, i) => {
        // Add two to the columnIndex to skip the row labels and totals column and start in column C
        return `'${formattedYearName}'!${this.columnIndexToExcelCol(i + 2)}${rowNumber}`;
      });

    // Number of formula parts depenss on number of POTENTIAL properties in most recent year of personal tax returns
    return `=${formulaParts.join("+")}`;
  }

  get initialGridState(): GridState {
    let index = 0;
    // we need a mapping from year to the column letter
    const yearToCol = this.coveredYears.reduce(
      (acc, year, i) => {
        acc[year] = this.colNumberToExcelCol(i + 2);
        return acc;
      },
      {} as Record<TaxFormYear, string>,
    );

    const mainPart = {
      // Row 1 - Source, e.g. "Personal Tax Return"
      source: {
        rowDataArray: [
          "Source",
          ...Object.values(this.underlying).map((year) => getSourceFromFormType(year[0]?.form)),
        ],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 2 - Entity
      entity: {
        rowDataArray: ["Entity", ...this.generateRepeatedValues(this.numberOfColumns, "Total")],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 3 - Property
      propertyName: {
        rowDataArray: [
          "Property",
          ...this.generateRepeatedValues(this.numberOfColumns, "All Properties"),
        ],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 4 - Year
      year: {
        rowDataArray: ["Year", ...this.coveredYears],
        rowDataType: "text",
        rowStyle: "metadata",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 5 - 1st blank row
      blankRow1: {
        rowDataArray: ["", ...this.generateRepeatedValues(this.numberOfColumns, "")],
        rowDataType: "text",
        rowStyle: "standard",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 6 - Gross Rents
      grossRents: {
        rowDataArray: [
          "Gross Rents",
          ...this.coveredYears.map((year) => this.generateSummaryYearSheetExcelFormula(6, year)),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 7 - Total Expenses
      totalExpenses: {
        rowDataArray: [
          "Total Expenses",
          ...this.coveredYears.map((year) => this.generateSummaryYearSheetExcelFormula(7, year)),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 8 - Net Income
      netIncome: {
        rowDataArray: [
          "Net Income",
          ...this.coveredYears.map((year) => this.generateSummaryYearSheetExcelFormula(8, year)),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 9 - Interest
      interest: {
        rowDataArray: [
          "Interest",
          ...this.coveredYears.map((year) => this.generateSummaryYearSheetExcelFormula(9, year)),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 10 - Depreciation
      depreciation: {
        rowDataArray: [
          "Depreciation",
          ...this.coveredYears.map((year) => this.generateSummaryYearSheetExcelFormula(10, year)),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 11 - Amortization
      amortization: {
        rowDataArray: [
          "Amortization",
          ...this.coveredYears.map((year) => this.generateSummaryYearSheetExcelFormula(11, year)),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 12 - Net Operating Income (Net Income + Interest + Depreciation + Amortization)
      noi: {
        //rowDataArray: ["Net Operating Income (NOI)", "=B8+B9+B10+B11"],
        rowDataArray: [
          "Net Operating Income (NOI)",
          ...this.coveredYears.map(
            (year) =>
              `=${yearToCol[year]}8+${yearToCol[year]}9+${yearToCol[year]}10+${yearToCol[year]}11`,
          ),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 13 - New Debt
      existingDebt: {
        rowDataArray: ["Existing Debt", ...this.generateRepeatedValues(this.numberOfColumns, 0)],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 14 - New Debt
      // Set New Debt  = [NOI ] * 1.25 - [Existing Debt]
      newDebt: {
        rowDataArray: [
          "New Debt",
          ...this.coveredYears.map((year) => `=${yearToCol[year]}12 / 1.25 - ${yearToCol[year]}13`),
        ],
        rowDataType: "number",
        rowStyle: "standard",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 15 - Total Debt Service (Existing Debt + New Debt)
      totalDebtService: {
        //rowDataArray: ["Total Debt Service", "=B13+B14"],
        rowDataArray: [
          "Total Debt Service",
          ...this.coveredYears.map((year) => `=${yearToCol[year]}13+${yearToCol[year]}14`),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 16 - Debt Service Coverage Ratio, a.k.a. DSCR (NOI / Total Debt Service)
      dscr: {
        rowDataArray: [
          "Debt Service Coverage Ratio",
          ...this.coveredYears.map((year) => `=${yearToCol[year]}12/${yearToCol[year]}15`),
        ],
        rowDataType: "ratio",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: ++index,
      },
      // Row 17 - Excess/Deficit (NOI - Total Debt Service)
      excessDeficit: {
        //rowDataArray: ["Excess/Deficit", "=B12-B15"],
        rowDataArray: [
          "Excess/Deficit",
          ...this.coveredYears.map((year) => `=${yearToCol[year]}12-${yearToCol[year]}15`),
        ],
        rowDataType: "number",
        rowStyle: "highlighted",
        isManagedByApp: true,
        index: ++index,
      },
    } as GridState;
    const excessDeficitIndex = Object.keys(mainPart).length;

    const excessDeficitRefs: string[] = this.coveredYears.map(
      (year) => `${yearToCol[year]}${excessDeficitIndex}`,
    );
    // Set New Debt (line 19) = [NOI (line 17)] * 1.25 - [Existing Debt (line 18)]
    const newDebtRefs: string[] = this.coveredYears.map((year) => `${yearToCol[year]}14`);
    const manyLoanData: ManyLoanCalculatorsData = {
      excessCashflows: excessDeficitRefs,
      lenderId: this.lenderId,
      newDebt: newDebtRefs,
    };
    const loanCalculators = new ManyLoanCalculatorsRendered(manyLoanData, excessDeficitIndex + 1);
    return {
      ...mainPart,
      ...loanCalculators.initialGridState,
    };
  }

  get colLabels(): string[] {
    return this.headers;
  }

  defaultRowLabels: PropertyWithYearsLabel[] = [
    "Gross Rents",
    "",
    "Net Income",
    "Interest",
    "Depreciation",
    "Amortization",
    "NOI",
    "Existing Debt",
    "New Debt",
    "Total Debt Service",
    "Debt Service Coverage Ratio",
    "Excess/Deficit",
  ];

  highlightedRowLabels: PropertyWithYearsLabel[] = [
    "NOI",
    "Total Debt Service",
    "Debt Service Coverage Ratio",
    "Excess/Deficit",
  ];

  percentageRowLabels: PropertyWithYearsLabel[] = [];
}
